/*

 Custom styles for Vertical Bank organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

.container__header-provider {
    background: #181F18 !important;
}

.login--form-logo-image {
    width: 185px !important;
}

.login--logo,
.header--logo,
.header-provider--logo {
    content: none !important;
    mask: url('../../images/custom_organization/logo.svg');
    mask-size: 100%;
    mask-repeat: no-repeat;
    background-color: white;
    height: 130px;
    width: 100%;
    max-width: 90px;
    margin-top: -5px;
}

.header-provider {
    width: 150px;
}

.carousel--color .carousel--item {
    position: relative;

    &::before {
        content: '';
        background-color: rgba($color: #000000, $alpha: 0.3);
        height: 100%;
        width: 100%;
        position: absolute;
    }
}
